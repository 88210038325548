import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Close } from 'app/components/Common/Close'
import { Gallery } from 'app/components/Common/Gallery'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import useLockedBody from 'app/utils/useLockedBody'
import React, { memo, useEffect, useState } from 'react'
import Masonry from 'react-masonry-component'

export interface Props {
  images: ImageProps[]
  thumbnails: ImageProps[]
  title?: string
}

export const GalleryMosaic = memo(function GalleryMosaic({
  images,
  thumbnails,
  title,
}: Props) {
  if (!images) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      <MasonryContainer
        options={{
          columnWidth: 1,
          percentPosition: true,
          transitionDuration: 0,
        }}
      >
        {thumbnails.map((item, index) => {
          counter = counter + 1
          return (
            <Thumb
              data-counter={counter}
              key={index}
              onClick={(e) => {
                if (!modalGalleryStatus) {
                  setLocked(true)
                  setModalGalleryStatus(true)
                } else {
                  setLocked(false)
                  setModalGalleryStatus(false)
                }
                const realClickedSlide =
                  Number(e.currentTarget.getAttribute('data-counter')) - 1
                setClickedSlide(realClickedSlide)
                setTimeout(() => {
                  setClickedSlide(null)
                }, 100)
              }}
            >
              <Image {...item} />
            </Thumb>
          )
        })}
      </MasonryContainer>

      <Modal className={modalGalleryStatus ? ' open' : undefined}>
        <Close
          onClick={() => {
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
          }}
        />
        <Gallery
          defaultCategory={title ? title : undefined}
          clickedSlide={clickedSlide}
          images={images}
          isOpen={modalGalleryStatus}
        />
      </Modal>
    </Container>
  )
})

const Container = styled.section`
  position: relative;
  padding: 7.5vw 12.5vw;
  padding-top: 0;
  top: -4.5vw;

  @media (max-width: 1439px) {
    padding: 7.5vw 1.5625rem;
    padding-top: 0;
  }

  @media (max-width: 1199px) {
    padding: 5rem 1.5625rem;
    padding-top: 2.5rem;
    top: 0;
  }
`

const Title = styled.h2`
  margin-bottom: 5vw;
  color: ${({ theme }) => theme.colors.variants.primaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: normal;
  font-size: 3.43vw;
  text-align: center;

  @media (max-width: 1199px) {
    margin-bottom: 3.125rem;
    font-size: 2.1875rem;
  }
`

const MasonryContainer = styled(Masonry)<MasonryProps>`
  margin-left: -3.12vw;
  margin-bottom: -3.12vw;

  @media (max-width: 575px) {
    margin-left: -0.9375rem;
    margin-bottom: -0.9375rem;
  }
`

const Thumb = styled.div`
  position: relative;
  width: calc(33.29% - 3.12vw);
  height: 33.75vw;
  aspect-ratio: 0.68;
  margin-bottom: 3.12vw;
  margin-left: 3.12vw;
  border-radius: 1.25vw;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    img {
      transform: scale(1.07);
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.25s ease-in-out;
  }

  @media (min-width: 1200px) {
    &:nth-of-type(2) {
      margin-top: 5.18vw;
    }
  }

  @media (max-width: 575px) {
    width: calc(49.9% - 0.9375rem);
    margin-bottom: 0.9375rem;
    margin-left: 0.9375rem;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.15s ease-out;
  z-index: -1;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`

interface MasonryProps {
  children?: React.ReactNode | React.ReactNode[]
}
